module.exports = [{
      plugin: require('/Users/robertdickert/src/rdickert/recipes/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/robertdickert/src/rdickert/recipes/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/robertdickert/src/rdickert/recipes/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://recipes.robertdickert.com"},
    },{
      plugin: require('/Users/robertdickert/src/rdickert/recipes/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
