/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// eslint-disable-next-line import/no-extraneous-dependencies
import Auth from '@aws-amplify/auth';

import { setUser } from './src/utils/auth';

// prettier-ignore
const onRouteUpdate = (state, page, pages) => { // eslint-disable-line
  Auth.currentAuthenticatedUser()
    .then(user => {
      const userInfo = {
        ...user.attributes,
        username: user.username,
      };
      setUser(userInfo);
    })
    .catch(err => { // eslint-disable-line
      // Ensure that window is defined.
      if (typeof window !== 'undefined') {
        window.localStorage.setItem('gatsbyUser', null);
      }
    });
};

export { wrapRootElement } from './src/apollo/wrap-root-element';

export default onRouteUpdate;
