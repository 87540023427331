import { ApolloClient } from 'apollo-client';
// eslint-disable-next-line no-unused-vars
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { RestLink } from 'apollo-link-rest';

// const httpLink = new HttpLink({ uri: Meteor.absoluteUrl('graphql') });

// polyfill headers for node rendering with rest link
// see https://github.com/apollographql/apollo-link-rest/issues/41#issuecomment-359893024
// eslint-disable-next-line global-require
global.Headers = global.Headers || require('fetch-headers');

const link = new RestLink({
  uri: 'https://y9bqf9b5v5.execute-api.us-east-1.amazonaws.com/development',
});

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  link,
  cache,
});

export default apolloClient;
