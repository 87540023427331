// use this util to perform console.log, console.error, etc.
// The advantages of using this instead of console.log directly are:
//  1. Show our intention of logging - this is for permanent code, not
//     debugging. Then temp console.logs can be flagged by eslint for removal.
//  2. Allow us to mock out this function in tests
//  3. Allow us to customize these functions (we can add analytics and/or error reporting,
//     or disable in prod)

/* eslint-disable no-console */

export default {
  log: console.log,
  warn: console.warn,
  error: console.error,
  table: console.table,
};
