// TODO: consider splitting this into a folder and splitting these functions into files

import { Auth } from 'aws-amplify';

import logger from './logger';

const setUser = user => {
  if (typeof window !== 'undefined') {
    window.localStorage.gatsbyUser = JSON.stringify(user);
  } else {
    // This shouldn't ever be called outside of a browser
    console.warn('NO USER IS SET AS WE ARE NOT IN A BROWSER ENV.');
  }
};

const getUser = () =>
  (typeof window !== 'undefined' && window.localStorage.gatsbyUser
    ? JSON.parse(window.localStorage.gatsbyUser)
    : null);

const isLoggedIn = () => {
  const user = getUser();

  return !!user;
};

const getCurrentUser = () => getUser();

const logIn = async (username, password) => {
  try {
    await Auth.signIn(username, password);
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const user = {
      ...cognitoUser.attributes,
      username: cognitoUser.username,
    };
    setUser(user);
    return { user, error: null };
  } catch (error) {
    logger.error('error on login: ', error, 'error');
    return { user: null, error };
  }
};

const logOut = async () => {
  try {
    await Auth.signOut();
    setUser(null);
    // eslint-disable-next-line consistent-return
    return { error: null };
  } catch (error) {
    logger.error('error on l: ', error, 'error');
    // eslint-disable-next-line consistent-return
    return { error };
  }
};

export { setUser, isLoggedIn, getCurrentUser, logIn, logOut };
