import React from 'react';
import { ApolloProvider } from 'react-apollo';
import PropTypes from 'prop-types';

import apolloClient from './client';

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={apolloClient}>{element}</ApolloProvider>
);

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};
